import React from "react";
import { Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "../../assets/css/Landing/table_res.css";

export default function PlanesTable(props) {
  let plan = props.plan;
  return (
    <Table responsive className="rescate-table planes_table">
      <thead>
        <tr>
          <th>Seguro</th>
          <th>Inicio vigencia seguro</th>
          <th>Prima mensual</th>
          <th>Cantidad primas pagadas</th>
          <th>Monto primas pagadas</th>
          <th>Disponible para retiro</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td title="Seguro">{plan.nombre}</td>
          <td title="Inicio vigencia seguro">{plan.fecha_inicio}</td>
          <td title="Prima mensual">
            <div>
              <NumberFormat
                value={plan.prima_mensual_uf}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"UF "}
                suffix={""}
                decimalScale={2}
              />
            </div>
            <div>
              <NumberFormat
                value={plan.prima_mensual_clp}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                suffix={"*"}
                decimalScale={0}
              />
            </div>{" "}
          </td>
          <td title="Cantidad primas pagadas"><div>{plan.primas_pagadas}</div></td>
          <td title="Monto primas pagadas">
            <div>
              <NumberFormat
                value={plan.monto_pagado_uf}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"UF "}
                suffix={""}
                decimalScale={2}
              />
            </div>
            <div>
              <NumberFormat
                value={plan.monto_pagado_clp}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                suffix={"*"}
                decimalScale={0}
                className=""
              />
            </div>{" "}
          </td>
          <td title="Disponible para retiro">
            <div>
              <NumberFormat
                value={plan.monto_disponible_uf}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"UF "}
                suffix={""}
                decimalScale={2}
              />
            </div>
            <div>
              <NumberFormat
                value={plan.monto_disponible_clp}
                displayType={"text"}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={"$"}
                suffix={"*"}
                decimalScale={0}
                className=""
              />
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}