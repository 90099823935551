import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container, Alert } from "react-bootstrap";
// import MetlifeService from "../../services/MetlifeDataService";
// import { Link } from "react-router-dom";
import NumericInput from "react-numeric-input";
import NumberFormat from "react-number-format";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

class BodyRescate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      personal: null,
      errors: {},
      fields: {},
      uf: 0,
      porcentaje: 0,
      rescate: {
        uf: 0,
        clp: 0,
      },
      saldo: {
        uf: 0,
        clp: 0,
      },
    };

    this.state.personal = this.props.state.personal;
    this.state.plan = this.props.state.plan;
    this.state.uf = this.props.state.uf;
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  //   calling web services
  handleValidation() {
    if (this.state.porcentaje === 0) {
      let errors = {};
      errors["porcentaje"] = "Debes elegir un % entre 1 y 100 para continuar.";//"El porcentaje no puede ser cero";
      this.setState({ errors: errors });
      return false;
    }

    return true;
  }
  requestRescate(e) {
    e.preventDefault();
    
    if (this.handleValidation()) {
      let retirar = {
        porcentaje: this.state.porcentaje,
        rescate: this.state.rescate,
        saldo: this.state.saldo,
      };
      this.props.history.push({
        pathname: "/bancarios",
        state: {
          personal: this.state.personal,
          plan: this.state.plan,
          uf: this.state.uf,
          retirar: retirar,
        },
      });
    }
  }

  myFormat(num) {
    return num + " %";
  }
  handlePercent(value) {
    this.setState({ errors: {} });
    if (value >= 0 && value <= 100) {
      let rescate = this.state.rescate;
      rescate.uf = (this.state.plan.monto_disponible_uf * value) / 100;
      rescate.clp = (this.state.plan.monto_disponible_clp * value) / 100;
      let saldo = this.state.saldo;
      saldo.uf = parseFloat(this.state.plan.monto_disponible_uf).toFixed(5) 
               - parseFloat(rescate.uf).toFixed(5);
      saldo.clp = parseFloat(this.state.plan.monto_disponible_clp).toFixed(0) 
                - parseFloat(rescate.clp).toFixed(0);
      this.setState({ rescate: rescate, saldo: saldo, porcentaje: value });
    }
  }

  render() {
    return (
      <Container className="mt-5">
        <Row className="subtitle mb-3">
          <Col>Solicitar rescate Vida con Ahorro</Col>
        </Row>
        <Col className="ml-4">
          <Row className="font-weight-bold mb-2">
            <Col><h3>Datos del rescate</h3></Col>
          </Row>
          {/* all */}
          <Row className=" mb-2">
            <Col md={3}>
              <Row className="font-weight-bold d-flex justify-content-start">
                <Col>Disponible para retiro</Col>
              </Row>
              <Row className=" d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.plan.monto_disponible_uf}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"UF "}
                />
                </Col>
              </Row>

              <Row className="d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.plan.monto_disponible_clp}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={0}
                  prefix={"$ "}
                  suffix={"*"}
                />
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row className="font-weight-bold d-flex justify-content-start">
                <Col>Porcentaje a retirar</Col>
              </Row>
              <Row>
                <Col>
                <NumericInput
                  className="rescute_percent"
                  min={0}
                  max={100}
                  value={this.state.porcentaje || 0}
                  format={this.myFormat.bind(this)}
                  onChange={this.handlePercent.bind(this)}
                />
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row className="font-weight-bold d-flex justify-content-start">
                <Col>Monto a retirar</Col>
              </Row>
              <Row className="d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.rescate.uf}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"UF "}
                />
                </Col>
              </Row>
              <Row className="d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.rescate.clp}
                  displayType={"text"}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={0}
                  prefix={"$ "}
                  suffix={"*"}
                />
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row className="font-weight-bold d-flex justify-content-start">
                <Col>Saldo después del retiro</Col>
              </Row>
              <Row className="d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.saldo.uf}
                  displayType={"text"}
                  className="font-weight-bold"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={2}
                  prefix={"UF "}
                />
                </Col>
              </Row>
              <Row className="d-flex justify-content-start">
                <Col>
                <NumberFormat
                  value={this.state.saldo.clp}
                  displayType={"text"}
                  className="font-weight-bold"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  decimalScale={0}
                  prefix={"$ "}
                  suffix={"*"}
                />
                </Col>
              </Row>
            </Col>
          </Row>

          
          <Row className="mb-2 font-weight-bold">
            <Col>
            <NumberFormat
                    value={this.state.uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"*Valor referencial calculado según el valor de la UF del día de hoy $"}
                    decimalScale={2}
                    className=""
                  />
            </Col>
          </Row>
          <Row className=" mb-2">
            <Col>
            El monto a retirar será calculado sobre el ahorro recaudado hasta la fecha. 
            Considerar que puede existir un desfase por las primas que se encuentren en curso y 
            que el monto final puede estar sujeto al impuesto a la renta, de acuerdo a lo definido por el SII, 
            incidiendo en el monto final a percibir.
            </Col>
          </Row>

          {this.state.errors["porcentaje"] &&
          <Row>
            <Col>
              <Alert className="msg-error">
                <b>{this.state.errors["porcentaje"]}</b>
              </Alert>
            </Col>
          </Row>
          }

          <Row className="mt-3 d-flex justify-content-center">
            <button
              className="info_btn"
              type="button"
              onClick={this.requestRescate.bind(this)}
            >
              <span className="ui-button-text ui-c ">Continuar</span>
            </button>
          </Row>
          <Row className="d-flex justify-content-center">
            {/* <Link to="/"> */}
            <p className="resend_code_label " onClick={this.props.logout}>
              Cerrar Sesión
            </p>
            {/* </Link> */}
          </Row>
        </Col>
      </Container>
    );
  }
}

export default withRouter(BodyRescate);
