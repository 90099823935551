import React, { Component, } from "react";
//import React, {Component} from "react";
import Header from "./Header";
import Banner from "./Banner";
import BodyRut from "./BodyRut";
// import BodyLogin from "./BodyLogin";
import Footer from "./Footer";

const refreshCacheAndReload = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });
  }
  // delete browser cache and hard reload
//  window.location.reload(true);
};

class Home extends Component {
  componentDidMount(){
    refreshCacheAndReload();  
  }

  render() {
    return (
      <div className="content">
        {/* <div class="card m-2"> */}
        <div className="card m-2">
          <Header />
          <Banner />
          {/* <div class="card-body"> */}
          <div className="card-body">
            <BodyRut />
            <hr/>
            <Footer />
          </div>
        </div>          
      </div>
    );
  }
}

export default Home;
