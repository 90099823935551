import "core-js/es/set";
import "core-js/es/map";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { useContext, useState, createContext } from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import PopupError from "./components/Error/PopupError";

import * as serviceWorker from "./serviceWorker";

// css from landing
import "./assets/css/Landing/main.min.css";
import "./assets/css/Landing/animate.css";
import "bootstrap/dist/css/bootstrap.min.css";

export var genericError = null;

export function Main(props) {
  const [show, setShow] = useState(false);
  let GEContext = createContext({show, setShow});
  genericError = useContext(GEContext);

  return (
    <React.StrictMode>
      <App />
      {show ? <PopupError setShow={setShow} /> : "" }
    </React.StrictMode>
  );
};

ReactDOM.render( 
  <Main />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();