import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';

import MetlifeService from "../../services/MetlifeDataService";

export default function PopReenviar(props) {
  const [show, setShow] = useState(false);
  // const [opt, setOpt] = useState(0);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  
  /*
  const saveOpcion = (e) => {
    if (e.target.id === "inline-radio-1") {
      setOpt(1);
    } else if (e.target.id === "inline-radio-2") {
      setOpt(2);
    } else if (e.target.id === "inline-radio-3") {
      setOpt(3);
    }

    // props.resendCode(".");
  };
  */

  const [value, setValue] = React.useState('sms');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const sendCode = () => {
    let data = {};
    data.rut = props.personal.rut;
    data.productCode = "400";
    if (value === "sms") {
      data.phoneNumber = props.personal.sms;
    } 

    if (value === "phone") {
      data.phoneNumber = props.personal.telefono;
    }

    data.email = props.personal.email;
    data.firstName = props.personal.firstName;
    data.lastName = props.personal.lastName;
    data.uri = '/' + value;
    data.policyNumber = props.personal.policyNumber;

    MetlifeService.requestCode(props.personal.token, data)
      .then((res) => {
        setShow(false);
      })
      .catch((err) => {
        console.log(err);
        setShow(false);
      });
  };

  return (
    <>
      <span className="modal_label" onClick={handleShow}>
        ¿No lo recibiste? Reenviar Código
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Reenviar Código</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={2}></Col>
              <Col sm={8}>
                <FormControl component="fieldset">
                  {/*<FormLabel component="legend">Plataforma</FormLabel>*/}
                  <RadioGroup aria-label="media" name="media1" value={value} onChange={handleChange}>
                    {props.sms.length > 0 && (<FormControlLabel  disabled={(props.sms == null || props.sms.length === 0) ? "disabled" : ""} value="sms" control={<Radio />} label={"SMS  " + props.sms} /> )}
                    {props.email.length > 0 && (<FormControlLabel disabled={(props.email == null || props.email.length === 0) ? "disabled" : ""}  value="mail" control={<Radio />} label={"Correo electrónico  " + props.email} /> )}
                    {props.phone.length > 0 && (<FormControlLabel disabled="true" value="phone" control={<Radio />} label={"Llamada Telefónica  " + props.phone} /> )}
                  </RadioGroup>
              </FormControl>
              </Col>
              <Col sm={2}></Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-md-center">
              <button className="info_btn" type="button" onClick={sendCode}>
                <span className="ui-button-text ui-c ">Solicitar código</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
