import React, { useState } from "react";
import { Container, Row, Col, Popover} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import serieImg from '../../assets/css/img/serie.jpg';
import "bootstrap/dist/css/bootstrap.css";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default function Popup(props) {
  const [show, setShow] = useState(false);

  const handleSend = () => { props.checkAccount("."); };

  const handleShow = () => setShow(true);

  const handleClose = () => {
    props.cleanPopup();
    setShow(false);
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Número de Documento</Popover.Title>
      <Popover.Content>
        <img className="img-fluid" src={serieImg} alt="Número de Documento"/>
      </Popover.Content>
    </Popover>
  );

  const Cedula = () => (
    <OverlayTrigger trigger="click" placement="top" overlay={popover}>
      <Button variant="">¿Necesitas ayuda?</Button>
    </OverlayTrigger>
  );

  return (
    <>
      <span className="label_bold" onClick={handleShow}>
        Haz click aquí
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ingresa a tus ahorros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={3}></Col>
              <Col sm={6}>
                <Row className="mt-3 bold font-weight-bold">RUT:</Row>
                <div className="mt-2">{props.rut}</div>
                <Row className="mt-5 bold font-weight-bold">
                  {" "}
                    Número de Documento:
                    <input
                      className="info_input mt-2 ml-2"
                      type="password"
                      name="cedula"
                      maxLength="9"
                      onChange={(e) => {
                        props.saveCedula(e.target.value);
                      }}
                    />
                </Row>
                <Row className="mt-5 bold font-weight-bold">
                  <Cedula />
                </Row>
              </Col>
              <Col sm={3}></Col>
            </Row>
            <Row className="justify-content-md-center" style={{ color: "#a94442",}}>{props.mensaje}</Row>
            <Row>
            
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-md-center">
              <button className="info_btn" type="button" onClick={handleSend}>
                <span className="ui-button-text ui-c ">Ver mis ahorros</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}