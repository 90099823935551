import React, { Component } from "react";
import Header from "../Home/Header";
import BodyBancario from "./BodyBancario";
import Footer from "../Home/Footer";

class DatosBancarios extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props.location.state != null) {
      this.state = props.location.state;
    } else {
      this.props.history.push("/");
    }
  }
  logout() {
    this.props.location.state = null;
    this.props.history.push("/");
  }
  render() {
    return (
      /**
      <div className="content">
        <Header />
        <BodyBancario state={this.state} logout={this.logout.bind(this)} />
        <Footer />
      </div>
      */

      <div className="content">
        <div class="card mt-4">
          <Header />
          <div class="card-body">
            <BodyBancario state={this.state} logout={this.logout.bind(this)} />
            <hr />
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default DatosBancarios;
