import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Form, Container, Card, Button, Alert, ListGroup, } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";

import loading from "../../assets/css/img/loading-orange.gif";
import Accordion from "react-bootstrap/Accordion";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
// import "primeflex/primeflex.css";
import { Dropdown } from "primereact/dropdown";

import btn_down from "../../assets/css/img/arrow-down.png";
// import btn_up from "../../assets/css/img/arrow-up.png";
import NumberFormat from "react-number-format";

class BodyBancario extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [{}, {}],
      errorDesc: null,
      personal: this.props.state.personal,
      plan: this.props.state.plan,
      retirar: this.props.state.retirar,
      uf: this.props.state.uf,
      opcion: null,
      val_opcion: false,
      fields: [
        {
          banco: "12",
          glosaBanco: "BancoEstado",
          tipo_cuenta: "",
          cuenta: "",
        },
        { banco: "", tipo_cuenta: "", cuenta: "" },
      ],
    };
  }

  //   calling web services
  handleValidation() {
    let fields = this.state.fields;
    let errors = [{}, {}];
    let opcion = this.state.opcion;
    let formIsValid = true;
    if (opcion != null) {
      this.setState({ val_opcion: false });
      //Validacion requerido
      ["banco", "tipo_cuenta", "cuenta"].map((campo) => {
        switch (campo) {
          case "banco":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Selecciona un Banco";
            break;
          case "tipo_cuenta":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Selecciona un tipo de cuenta";
            break;
          case "cuenta":
            if (!fields[opcion][campo])
              errors[opcion][campo] = "Ingresa tu cuenta";
            break;

          default:
            break;
        }

        return errors;
      });
      if (Object.keys(errors[opcion]).length > 0) formIsValid = false;
    } else {
      this.setState({ val_opcion: true });
      formIsValid = false;
      this.setState({ isFetching: false });
    }
    this.setState({ errors: errors });

    return formIsValid;
  }

  updateBanco(e) {
    this.setState({ isFetching: true });
   
    e.preventDefault();
    let bancarios = this.state.fields[this.state.opcion];

    if (this.handleValidation()) {
      let request = {
        branch: this.state.plan.branch,
        product: this.state.plan.product,
        policy: this.state.plan.policy,
        idClient: this.state.personal.idClient,
        percentageRescue: this.state.retirar.porcentaje,
        numberTicket: this.state.plan.ticketNumber,
        mail: this.state.personal.email,
        bankAssociatedAccount: bancarios.banco,
        bankAccount: bancarios.cuenta,
        accountType2: bancarios.tipo_cuenta,
        // client data
        nombre: this.state.personal.nombre,
        nombrePoliza: this.state.plan.nombre,
        montoDisponibleUF: this.state.plan.monto_disponible_uf,
        montoDisponibleCL: this.state.plan.monto_disponible_clp,
        montoRetiroUF: this.state.retirar.rescate.uf,
        montoRetiroCL: this.state.retirar.rescate.clp,
        saldoUF: this.state.retirar.saldo.uf,
        saldoCL: this.state.retirar.saldo.clp,
        uf: this.state.uf,
        rut: this.state.personal.rut,
        sms: this.state.personal.sms,
        telefono: this.state.personal.telefono,
      };

      MetlifeService.requestRescate(this.state.personal.token, request)
        .then((res) => {
          this.setState({ isFetching: false });
          let dto = res.data.listCrewServiceDTO[0];
          
          // set ticket numbre null
          let policyNumber= request.policy;
          this.state.personal.policies.forEach(item => {
            if (policyNumber === item.policy) {
              item.ticketNumber = null;
            }  
          });
          
          this.props.history.push({
            pathname: "/resumen",
            state: {
              personal: this.state.personal,
              plan: this.state.plan,
              retirar: this.state.retirar,
              uf: this.state.uf,
              bancarios: this.state.fields[this.state.opcion],
              solicitud: "Rescate de fondo de ahorro",
              num_solicitud: dto.numberProposal,
            },
          });
        })
        .catch((err) => {
          console.log("create rescue error", err);
          console.log("create rescue error status", typeof(err.response.status));
          this.setState({ isFetching: false });
          let status;
          if ( (typeof err === "undefined") || (typeof err.response.status === "undefined") ) {
            status = 500;
          } else {
            status = err.response.status;
          }
          if (status === 403) {
            this.setState({ 
              isFetching: false, 
              errorDesc: "Lo sentimos, la sesión ha expirado!"
            });
            this.props.history.push({
              pathname: "/",
              state: {
                personal: null,
                metodo: null,
              },
            });
          }

          if (status === 409) {
            this.setState({ 
              isFetching: false,
              errorDesc: "Lo sentimos, existe un error en la solicitud!"
            });
            this.props.history.push({
              pathname: "/",
              state: {
                personal: null,
                metodo: null,
              },
            });
          }

          if (status === 500) {
            this.setState({ 
              error: true, 
              isFetching: false,
              errorDesc: "Lo sentimos, se produjo un error en el sistema!"
             });
            // delete
            this.props.history.push({
              pathname: "/bancarios",
              state: {
                personal: this.state.personal,
                plan: this.state.plan,
                retirar: this.state.retirar,
                uf: this.state.uf,
                bancarios: this.state.fields,
                num_solicitud: 0,
                solicitud: "Rescate de fondo de ahorro",
                forma_pago: "Deposito en cuenta",
              },
            });
          }
          console.log("system error: ", this.state.errorDesc);
        });
    } else {
      this.setState({ isFetching: false });
    }
  }

  handlePercent(value) {
    if (value >= 0 && value <= 100) {
      let rescate = this.state.rescate;
      rescate.uf = (this.state.monto.uf * value) / 100;
      rescate.clp = (this.state.monto.clp * value) / 100;
      let saldo = this.state.saldo;
      saldo.uf = this.state.monto.uf - rescate.uf;
      saldo.clp = this.state.monto.clp - rescate.clp;
      this.setState({ rescate: rescate, saldo: saldo, porcentaje: value });
    }
  }

  render() {
    const bancos = [
      { label: "BANCO DE CHILE", value: "1" },
      { label: "BANCO SCOTIABANK", value: "14" },
      { label: "BANCO DE CRÉDITO E INVERSIONES", value: "16" },
      { label: "BANCO BICE", value: "28" },
      { label: "HSBC BANK USA", value: "31" },
      { label: "BANCO SANTANDER CHILE", value: "37" },
      { label: "BANCO ITAU CORPBANCA", value: "39" },
      { label: "BANCO SECURITY", value: "49" },
      { label: "SCOTIABANK AZUL (EX BANCO BBVA)", value: "504" },
      // { label: "TRANSBANK", value: "999" },
      // { label: "BANCO ESTADO", value: "12" },
      { label: "BANCO FALABELLA", value: "51" },
      { label: "BANCO RIPLEY", value: "53" },
      { label: "BANCO CONSORCIO", value: "55" },
      { label: "BANCO INTERNACIONAL", value: "9" },
      { label: "BANCO BTG PACTUAL CHILE", value: "59" },
    ];
    const cuentas_be = [
      { label: "Cuenta Corriente", value: "1" },
      { label: "Cuenta Vista", value: "4" },
      { label: "Ahorro", value: "3" },
    ];
    const cuentas = [
      { label: "Cuenta Corriente", value: "1" },
      { label: "Cuenta Vista", value: "4" },
    ];
    return (
      <Container className="mt-5">
        <Row className="subtitle mb-3">Solicitar rescate Vida con Ahorro</Row>
        <Col className="ml-4">
          <Row className="font-weight-bold mb-2">Datos para abono</Row>
          <Row className="mb-3">
            Selecciona como prefieres obtener tu rescate:
          </Row>
          {/* all */}
          <Accordion defaultActiveKey="0" className="planes_accordion">
            <Card>
              <Card.Header>
                <span className="planes_head">
                  <Form.Check
                    inline
                    type="radio"
                    name="info_opt"
                    label="BancoEstado"
                    className="modal_label h5"
                    onChange={(e) => {
                      this.setState({ opcion: 0, val_opcion: false });
                    }}
                    id={"inline-radio-0"}
                  />
                </span>
                <Accordion.Toggle as={Button} variant="link" eventKey={"0"}>
                  <img alt="arrow down" src={btn_down} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={"0"}>
              <Card>
                <Card.Body style={{ height: "350px" }}>
                  <Container fluid="md">
                    <Row className="md-4">
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>
                            RUT titular (asegurado)
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.personal.rut}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>Banco</Col>
                        </Row>
                        <Row>
                          <Col>
                            {this.state.fields[0].glosaBanco}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>
                          *Tipo de cuenta
                          </Col>
                        </Row>
                        <Row>
                          <Col fluid>
                            <Dropdown 
                              size="sm"
                              value={this.state.fields[0].tipo_cuenta}
                              options={cuentas_be}
                              onChange={(e) => {
                                let fields = this.state.fields;
                                fields[0].tipo_cuenta = e.value;
                                let errores = this.state.errors;
                                errores[0]["tipo_cuenta"] = null;
                                this.setState({ errors: errores });
                              }}
                              placeholder="Seleccione..."
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col></Col>
                        </Row>
                      </Col>
                      <Col mb={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>*Nr. de cuenta</Col>
                        </Row>
                        <Row>
                            <Col>
                              <NumberFormat
                                format="####################" 
                                onChange={(e) => {
                                  let fields = this.state.fields;
                                  fields[0].cuenta = e.target.value;
                                  this.setState({ fields: fields });
                                  let errores = this.state.errors
                                  errores[0]["cuenta"] = null;
                                  this.setState({ errors: errores})
                                }}
                                name="cuenta" 
                                value={this.state.fields[0].cuenta || ""} />
                            </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
              </Accordion.Collapse>
            </Card>
            {/* second  */}
            <Card>
              <Card.Header>
                <span className="planes_head">
                  <Form.Check
                    inline
                    type="radio"
                    name="info_opt"
                    label="Otros Bancos"
                    className="modal_label h5"
                    onChange={(e) => {
                      this.setState({ opcion: 1, val_opcion: false });
                    }}
                    id={"inline-radio-1"}
                  />
                </span>
                <Accordion.Toggle as={Button} variant="link" eventKey={"1"}>
                  <img alt="arrow down" src={btn_down} />
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey={"1"}>
                <Card.Body style={{ height: '370px' }}>  
                  <Container>
                    <Row>
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>RUT titular (asegurado)</Col>
                        </Row>
                        <Row>
                          <Col>{this.state.personal.rut}</Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>Banco</Col>                        
                        </Row>
                        <Row>
                          <Col>
                          <Dropdown
                            value={this.state.fields[1].banco}
                            options={bancos}
                            onChange={(e) => {
                              let fields = this.state.fields;
                              fields[1].banco = e.value;
                              this.setState({ fields: fields });
                              let errores = this.state.errors;
                              errores[1]["banco"] = null;
                              this.setState({ errors: errores });
                            }}
                            placeholder="Seleccione un Banco"
                          />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} className="md-2">
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>*Tipo de cuenta</Col>
                        </Row>
                        <Row>
                          <Col>
                          <Dropdown
                            value={this.state.fields[1].tipo_cuenta}
                            options={cuentas}
                            className="dropOpts"
                            onChange={(e) => {
                              let fields = this.state.fields;
                              fields[1].tipo_cuenta = e.value;
                              this.setState({ fields: fields });
                              let errores = this.state.errors;
                              errores[1]["tipo_cuenta"] = null;
                              this.setState({ errors: errores });
                            }}
                            placeholder="Seleccione un tipo"
                          />
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row className="font-weight-bold d-flex justify-content-start mt-2">
                          <Col>*Nr. de cuenta</Col>
                        </Row>
                        <Row>
                          <Col>
                          <input
                            type="text"
                            name="cuenta"
                            placeholder=""
                            maxLength="13"
                            value={this.state.fields[1].cuenta || ""}
                            onChange={(e) => {
                              let fields = this.state.fields;
                              fields[1].cuenta = e.target.value;
                              this.setState({ fields: fields });
                              let errores = this.state.errors;
                              errores[1]["cuenta"] = null;
                              this.setState({ errors: errores });
                            }}
                          />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                   
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          
          {this.state.val_opcion && 
          <Row className="mt-3 justify-content-center">
            <Col>
              <Alert className="msg-error">
                <b>Seleccione un banco</b>
              </Alert>
            </Col>
          </Row>
          }

          {this.state.errorDesc &&
            <Row className="mt-3 justify-content-center">
              <Col>
                <Alert className="msg-error-danger">
                  <b>{this.state.errorDesc}</b>
                </Alert>
              </Col>
            </Row>
          }

          {(this.state.errors[1]["banco"] || this.state.errors[1]["tipo_cuenta"] || this.state.errors[1]["cuenta"]) &&
            <Row className="mt-3 justify-content-center">
              <Col>
                  <ListGroup>
                  {this.state.errors[1]["banco"] && <ListGroup.Item variant="primary"><b>{this.state.errors[1]["banco"]}</b></ListGroup.Item>}
                    {this.state.errors[1]["tipo_cuenta"] && <ListGroup.Item variant="primary"><b>{this.state.errors[1]["tipo_cuenta"]}</b></ListGroup.Item>}
                    {this.state.errors[1]["cuenta"] && <ListGroup.Item variant="primary"><b>{this.state.errors[1]["cuenta"]}</b></ListGroup.Item>}
                  </ListGroup>
              </Col>
            </Row>                      
          }
          {(this.state.errors[0]["tipo_cuenta"] || this.state.errors[0]["cuenta"]) &&
                    <Row className="mt-3 justify-content-center">
                      <Col>
                          <ListGroup>
                            {this.state.errors[0]["tipo_cuenta"] && <ListGroup.Item variant="primary"><b>{this.state.errors[0]["tipo_cuenta"]}</b></ListGroup.Item>}
                            {this.state.errors[0]["cuenta"] && <ListGroup.Item variant="primary"><b>{this.state.errors[0]["cuenta"]}</b></ListGroup.Item>}
                          </ListGroup>
                      </Col>
                    </Row>                      
                    }

          <Row className="mt-3 md-3 justify-content-center">
            De existir algún rechazo al realizar la transferencia electrónica a
            su cuenta bancaria, se emitirá un vale vista a su nombre que podrá
            retirar en cualquier sucursal a nivel nacional de Banco Estado.
          </Row>
          <Row className="mt-3 d-flex justify-content-center">
            {this.state.isFetching ? (
              <div className="btn-continuar ">
                <img className="center" src={loading} alt="loading" />
              </div>
            ) : (
              <button
                className="info_btn"
                type="button"
                onClick={this.updateBanco.bind(this)}
              >
                <span className="ui-button-text ui-c ">Confirmar solicitud</span>
              </button>
            )}
          </Row>

          <Row className="d-flex justify-content-center">
            <p className="resend_code_label " onClick={this.props.logout}>
              Cerrar Sesión
            </p>
          </Row>
        </Col>
      </Container>
    );
  }
}

export default withRouter(BodyBancario);
