import React from "react";
import { withRouter } from "react-router-dom";
import { replaceAll, validaRut, hideEmail, hideSms } from "../tools/Tools";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";
import loading from "../../assets/css/img/loading-orange.gif";
import Popup from "./Popup";
import PopupRut from "./PopupRut";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import ReactTooltip from "react-tooltip";

import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();

class BodyRut extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: false,
      cedula: "",
      rutcdv: "",
      showBtnIng: true,
      fields: {},
      errors: {},
      show: false,
      personal: {
        rut: null,
        nombre: null,
        firstName: null,
        lastName: null,
        sms: "",
        email: "",
        telefono: "",
        token: null,
        policies: {
          policy: {
            descriptionBranch: "",
            descriptionProduct: "",
            descriptionState: "",
            endDate: "",
            nidServices: "",
            nstatus: "",
            numberApaymentPay: "",
            policy: "",
            product: "",
            startDate: "",
            status: "",
            ticketNumber: "",
          },
        },
      },
      mensaje: "",
      errorMsg: false,
      option: null,
      token: null,
      validar_rut: true,
    };

    //this.handlePlan = this.handlePlan.bind(this);
  }

  // Calling web services
  handlePlan = (value) => {
    if (value === null) {
      this.setState({ expired: "true" });
    } else {
      MetlifeService.getToken({ site: value })
        .then((res) => {
          if (res && res.headers) {
            let personal = this.state.personal;
            personal.token = res.headers.authorization;
            this.setState({
              personal: personal,
              token: res.headers.authorization,
            });
          } else {
            console.error("Respuesta inesperada del servidor:", res);
            this.setState({ error: true });
          }
        })
        .catch((error) => {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.redirect
          ) {
            // Redirecciona al URL error-recaptcha si el puntaje es menor a 0.5
            window.location.href = error.response.data.redirect;
            return;
          }
          console.error(error);
          this.setState({ error: true });
        });
    }
  };

  sendRut() {
    if (this.state.errors["rut"] !== "") return;

    this.setState({ isFetching: true });
    let rut = this.state.rutcdv;
    let rutArray = rut.split("-");
    let bodyRut = rutArray[0];
    let clientId = bodyRut.padStart(14, 0);

    MetlifeService.getData(
      {
        // idInsured: this.state.fields.rut,
        idInsured: clientId,
        idSponsor: null,
        state: "1",
        nuserCode: "2",
      },
      this.state.token
    )
      .then((res) => {
        let clientData = {};

        // let errors = {};
        if (res.data.listRClientPolDTO.length === 0) {
          // errors["rut"] = genericErrMsg; //res.data.message;

          this.setState({
            // errors: errors,
            errorMsg: true,
            showBtnIng: true,
            personal: {
              rut: this.state.fields.rut,
              idClient: "",
              nombre: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              sms: "",
              telefono: "",
              sms_hide: "SMS - ",
              email_hide: "Correo electrónico - ",
              tel_hide: "Llamada telefónica - ",
              token: this.state.token,
              policies: {},
            },
            validar_rut: true,
          });
        } else if (
          res.data.listRClientPolDTO.length === 1 &&
          clientData.errorCode != null
        ) {
          // console.log(errorsMap);
          // console.log(errorsMap[clientData.errorCode]);
          // errors["rut"] = genericErrMsg; //(typeof(errorsMap[clientData.errorCode]) === "undefined" ? clientData.errorMessage : errorsMap[clientData.errorCode]);
          this.setState({
            // errors: errors,
            errorMsg: true,
            showBtnIng: true,
            personal: {
              rut: this.state.fields.rut,
              idClient: "",
              nombre: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              sms: "",
              telefono: "",
              sms_hide: "SMS - ",
              email_hide: "Correo electrónico - ",
              tel_hide: "Llamada telefónica - ",
              token: this.state.token,
              policies: {},
            },
            validar_rut: true,
          });
        } else {
          if (res.data.listRClientPolDTO.length > 0) {
            clientData = res.data.listRClientPolDTO[0];
          }
          if (res.data.listRClientPolDTO.length > 1) {
            var BreakException = {};
            try {
              res.data.listRClientPolDTO.forEach((element) => {
                if (element.errorCode == null) {
                  clientData = element;
                  throw BreakException;
                }
              });
            } catch (e) {
              console.log("break", clientData);
            }
          }

          this.setState({
            personal: {
              rut: this.state.fields.rut,
              idClient: clientId,
              policyNumber: clientData.policy,
              nombre:
                clientData.clientFirstName + " " + clientData.clientLastName,
              firstName: clientData.clientFirstName,
              lastName: clientData.clientLastName,
              email:
                clientData.clientEmail === null ? "" : clientData.clientEmail,
              sms:
                clientData.clientMobilPhoneNumber === null
                  ? ""
                  : clientData.clientMobilPhoneNumber,
              telefono:
                clientData.clientPhoneNumber === null
                  ? ""
                  : clientData.clientPhoneNumber,
              sms_hide: "SMS - " + hideSms(clientData.clientMobilPhoneNumber),
              email_hide:
                "Correo electrónico - " + hideEmail(clientData.clientEmail),
              tel_hide:
                "Llamada telefónica - " + hideSms(clientData.clientPhoneNumber),
              token: this.state.token,
              policies: res.data.listRClientPolDTO,
            },
            validar_rut: false,
            errorMsg: false,
            show: false,
            showBtnIng: false,
          });
        }

        this.setState({ isFetching: false });
      })
      .catch((err) => {
        console.log(err);
        // console.log("name", err.name);
        console.log("message", err.message);
        console.log("response", err.response);

        let status;
        if (err.message === "Network Error") {
          status = 503;
        } else {
          status =
            typeof err.response === "undefined" ? 403 : err.response.status;
        }

        console.log("status", status);

        if (status === 409) {
          // let errors = {};
          // errors["rut"] = genericErrMsg;
          this.setState({
            // errors: errors,
            errorMsg: true,
            personal: {
              rut: this.state.fields.rut,
              idClient: "",
              nombre: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              sms: "",
              telefono: "",
              sms_hide: "SMS - ",
              email_hide: "Correo electrónico - ",
              tel_hide: "Llamada telefónica - ",
              token: this.state.token,
              policies: {},
            },
            validar_rut: true,
            showBtnIng: true,
          });
        }
        if (status === 500) {
          // let errors = {};
          // errors["rut"] = genericErrMsg;
          this.setState({
            // errors: errors,
            errorMsg: true,
            personal: {
              rut: this.state.fields.rut,
              idClient: "",
              nombre: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              sms: "",
              telefono: "",
              sms_hide: "SMS - ",
              email_hide: "Correo electrónico - ",
              tel_hide: "Llamada telefónica - ",
              token: this.state.token,
              policies: {},
            },
            validar_rut: true,
          });
        }
        if (status === 403) {
          this.setState({ isFetching: false });
          this.props.history.push({
            pathname: "/",
            // state: {
            //   personal: this.state.personal,
            //   metodo: this.state.option,
            // },
          });
        }

        if (status === 503) {
          console.log(status);
          // let errors = {};
          // errors["rut"] =
          // "Servicio no disponible, por favor intente más tarde.";
          this.setState({
            // errors: errors,
            errorMsg: true,
            personal: {
              rut: this.state.fields.rut,
              idClient: "",
              nombre: "",
              firstName: "",
              lastName: "",
              phoneNumber: "",
              email: "",
              sms: "",
              telefono: "",
              sms_hide: "SMS - ",
              email_hide: "Correo electrónico - ",
              tel_hide: "Llamada telefónica - ",
              token: this.state.token,
              policies: {},
            },
            validar_rut: true,
            showBtnIng: true,
          });
        }

        this.setState({ error: true });
        this.setState({ isFetching: false });
      });
  }
  sendCode() {
    this.setState({ isFetching: true });
    let data = {};
    data.rut = this.state.fields.rut;
    data.metodo =
      this.state.option === "0"
        ? this.state.personal.sms
        : this.state.personal.email;

    data.uri = this.state.option === "0" ? "/sms" : "/mail";
    data.productCode = "400";
    data.phoneNumber = this.state.personal.sms;
    data.email = this.state.personal.email;
    data.firstName = this.state.personal.firstName;
    data.lastName = this.state.personal.lastName;
    data.policyNumber = this.state.personal.policyNumber;

    MetlifeService.requestCode(this.state.token, data)
      .then((res) => {
        this.setState({ isFetching: false });
        this.props.history.push({
          pathname: "/validateCode",
          state: {
            personal: this.state.personal,
            metodo: this.state.option,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true, show: true });
        this.setState({ isFetching: false });
        let errors = {};
        errors["rut"] = "Solicitud incorrecta";
        // delete
        this.props.history.push({
          pathname: "/",
          state: {
            personal: this.state.personal,
            metodo: this.state.option,
            errors: errors,
            // errorMsg:true,
          },
        });
      });
  }
  // handle fields
  handleChange(field, e) {
    if (field === "optSms") {
      this.setState({ option: "0" });
    } else if (field === "optEmail") {
      this.setState({ option: "1" });
    }
  }

  handleRut(field, e) {
    let fields = this.state.fields;
    let labelRut = e.target.value;

    labelRut = labelRut.replace("-", "");
    //labelRut = labelRut.replace("-", "");
    labelRut = labelRut.replace(".", "");
    //labelRut = labelRut.replace(".", "");
    labelRut = labelRut.replace(".", "");
    //labelRut = labelRut.replace(".", "");

    if ("rut" in this.state.fields) {
      if (labelRut.length >= 2) {
        labelRut = labelRut.slice(0, -1) + "-" + labelRut.slice(-1);
      }
      if (labelRut.length >= 6) {
        labelRut = labelRut.slice(0, -5) + "." + labelRut.slice(-5);
      }
      if (labelRut.length >= 10) {
        labelRut = labelRut.slice(0, -9) + "." + labelRut.slice(-9);
      }
    }

    fields[field] = labelRut;
    this.setState({ fields });
    if (e.type === "blur") {
      let errors = {};
      let rut = replaceAll(labelRut.toString(), [",", "."], "");
      if (!validaRut(rut)) {
        errors["rut"] = "Ingresa un Rut válido";
        this.setState({
          errors: errors,
          errorMsg: false,
          show: true,
          showBtnIng: true,
        });
      } else {
        errors["rut"] = "";
        this.setState({
          errors: errors,
          errorMsg: false,
          show: false,
          showBtnIng: true,
        });
        //this.sendRut(rut); //se debe poner en boton.
        this.setState({ rutcdv: rut });
        //this.handlePlan();
      }
    }
    if (e.type === "change") {
      this.setState({ showBtnIng: true, show: false });
    }
  }

  checkAccount(value) {
    let rut = replaceAll(this.state.personal.rut.toString(), [",", "."], "");
    let data = { rut: rut, serie_rut: this.state.cedula };

    MetlifeService.validateRut(this.state.token, data)
      .then((res) => {
        this.setState({ isFetching: false });
        if (res.data === "SI") {
          this.setState({ mensaje: "" });
          this.props.history.push({
            pathname: "/main",
            state: this.state.personal,
          });
        } else {
          this.setState({
            mensaje:
              "Según nuestros registros el número de serie ingresado no corresponde a su RUT, favor intentar con otro medio de ingreso a la plataforma.",
          });
        }
        this.setState({ cedula: "" });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: true });
        this.setState({ isFetching: false });
        this.setState({ cedula: "" });
        this.setState({ mensaje: "Servicio no disponible." });
      });
  }

  saveCedula(value) {
    this.setState({ cedula: value });
  }
  componentDidMount() {
    const interval = 25 * 60 * 1000;
    recaptchaRef.current.execute();

    setInterval(() => {
      recaptchaRef.current.reset();
      recaptchaRef.current.execute();
    }, interval);
  }

  cleanPopup() {
    this.setState({
      rut: "",
      rutcdv: "",
      showBtnIng: true,
      mensaje: "",
      validar_rut: true,
      errors: {},
      show: false,
      fields: {},
      isFetching: false,
      personal: {
        sms: "",
        email: "",
        telefono: "",
      },
    });
  }

  render() {
    return (
      <Container fluid="md">
        <Row>
          <Col md={4} className="info_left">
            <div>Consulta aquí tus seguros con ahorro</div>
          </Col>
          <Col md={8}>
            <Form
              onSubmit={() => {
                recaptchaRef.current.execute();
              }}
            >
              <Row className="mb-3">
                <Col className="info_right">
                  Ingrese su RUT y seleccione la opcion donde quiere recibir su
                  código de verificación.
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="info_right">
                  Este código le permitirá ingresar al portal y acceder a su
                  información
                </Col>
              </Row>
              {/* </Form>{this.state.token === null ? (
              <div></div>
            ) : ( */}
              <React.Fragment>
                <Row className="mb-3">
                  <Col>
                    <b>R.U.T</b>
                  </Col>
                </Row>

                {this.state.isFetching ? (
                  <div className="btn-continuar">
                    <img className="center" src={loading} alt="loading" />
                  </div>
                ) : (
                  <Row className="mb-3">
                    <Col className="col-mb-3">
                      {this.state.show && (
                        <ReactTooltip
                          id="msgErrorRut"
                          place="top"
                          effect="solid"
                          className="tooptipcls"
                        >
                          {this.state.errors["rut"]}
                        </ReactTooltip>
                      )}
                      <input
                        type="text"
                        name="rut"
                        placeholder="11.111.111-1"
                        onChange={this.handleRut.bind(this, "rut")}
                        onBlur={this.handleRut.bind(this, "rut")}
                        value={this.state.fields["rut"] || ""}
                        data-tip
                        data-for="msgErrorRut"
                      />
                    </Col>
                  </Row>
                )}

                {this.state.show && (
                  <Row className="mb-3">
                    <Col className="col-mb-3">
                      <Alert className="msg-error">
                        <b>{this.state.errors["rut"]}</b>
                      </Alert>
                    </Col>
                  </Row>
                )}

                {this.state.showBtnIng && !this.state.isFetching && (
                  <Row className="mb-3">
                    <Col>
                      <button
                        className="info_btn"
                        type="button"
                        onClick={this.sendRut.bind(this)}
                      >
                        <span className="ui-button-text ui-c ">Ingresar</span>
                      </button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
              <Row>
                <Col>{this.state.errorMsg ? <PopupRut /> : ""}</Col>
              </Row>

              {this.state.validar_rut ? (
                <div></div>
              ) : (
                <React.Fragment>
                  {!this.state.isFetching && !this.state.showBtnIng && (
                    <Row className="info_label">
                      {" "}
                      <Col>Opción código de verificación</Col>{" "}
                    </Row>
                  )}
                  {!this.state.isFetching &&
                    !this.state.showBtnIng &&
                    this.state.personal.sms.length > 0 && (
                      <Row>
                        <Col>
                          <Form.Check
                            inline
                            label={this.state.personal.sms_hide || "SMS - "}
                            type="radio"
                            onChange={this.handleChange.bind(this, "optSms")}
                            name="info_opt"
                            id="inline-radio-1"
                            disabled={
                              this.state.personal.sms === null ||
                              this.state.personal.sms.length === 0
                                ? "disabled"
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    )}

                  {!this.state.isFetching &&
                    !this.state.showBtnIng &&
                    this.state.personal.email.length > 0 && (
                      <Row className="info_form">
                        <Col>
                          <Form.Check
                            inline
                            label={
                              this.state.personal.email_hide ||
                              "Correo electrónico - "
                            }
                            type="radio"
                            onChange={this.handleChange.bind(this, "optEmail")}
                            name="info_opt"
                            id="inline-radio-2"
                            disabled={
                              this.state.personal.email === null ||
                              this.state.personal.email.length === 0
                                ? "disabled"
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                    )}

                  {!this.state.isFetching &&
                    !this.state.showBtnIng &&
                    this.state.personal.telefono.length > 0 && (
                      <Row className="info_form">
                        <Col>
                          <Form.Check
                            inline
                            label={
                              this.state.personal.tel_hide ||
                              "Llamada telefónica - "
                            }
                            type="radio"
                            onChange={this.handleChange.bind(this, "optSms")}
                            name="info_opt"
                            id="inline-radio-1"
                            disabled="disabled"
                          />
                        </Col>
                      </Row>
                    )}
                  {!this.state.isFetching && !this.state.showBtnIng && (
                    <Row>
                      <Col>
                        ¿Tienes problemas para ingresar? &nbsp;
                        <Popup
                          rut={this.state.personal.rut}
                          mensaje={this.state.mensaje}
                          checkAccount={this.checkAccount.bind(this)}
                          saveCedula={this.saveCedula.bind(this)}
                          cleanPopup={this.cleanPopup.bind(this)}
                        />
                      </Col>
                    </Row>
                  )}
                  {!this.state.isFetching && !this.state.showBtnIng && (
                    <Row>
                      <Col>
                        <button
                          className="info_btn"
                          type="button"
                          onClick={this.sendCode.bind(this)}
                          disabled={
                            this.state.option === null ? "disabled" : ""
                          }
                        >
                          <span className="ui-button-text ui-c ">
                            Solicitar código
                          </span>
                        </button>
                      </Col>
                    </Row>
                  )}
                </React.Fragment>
              )}
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LcZKQ4pAAAAANhINLULPL2Q3G0rP7m8NW8JZgXi" //site prod
                onChange={this.handlePlan}
              />
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BodyRut);
