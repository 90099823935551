import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SimularTable from "./SimularTable";

import MetlifeService from "../../services/MetlifeDataService";


export default function PopRescates(props) {
  const [show, setShow] = useState(false);
  const [simulations, setSimulations] = useState([]);

  //const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShow = () => {
    let plan = props.plan;
    if (plan.ticketNumber === null) {
      setShow(true);
      setSimulations([])
    } else {
      let request= {
        availableBalance : plan.monto_disponible_uf,
        apaymentUf : plan.prima_mensual_uf
      };
  
      MetlifeService.getSimulation(props.token, request).then((res) => {
        setShow(true);
        setSimulations(res.data.simulation);
      }).catch((err) => {
        setShow(true);
        setSimulations([])
        console.log("getSimulation ERROR", err);
      });
    }
  }  



  return (
    <>
      {/* <span className="modal_label" onClick={handleShow}>
        Simular ahorros
      </span> */}
      <button className="btn-lnk" type="button" onClick={handleShow}>Simular ahorros</button>

      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Simulador de retiros</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <SimularTable 
                plan={props.plan} 
                uf={props.uf} 
                token={props.token} 
                simulations={simulations} />
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-md-center">
              <button className="info_btn" type="button" onClick={handleClose}>
                <span className="ui-button-text ui-c ">Aceptar</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
