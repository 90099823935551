import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container, Alert } from "react-bootstrap";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

class BodyPersonales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      fields: {},
      datos: this.props.datos,
    };

    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    this.validEmailRegex = RegExp(mailformat);

    /*
    this.validEmailRegex = RegExp(
      /^(([^<>()[\] .,;:\s@"]+(\.[^<>()[\] .,;:\s@ "]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    */
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  //   calling web services
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //Validacion requerido
    ["telefono", "sms", "email"].map((campo) => {
      switch (campo) {
        case "sms":
          if (!this.state.datos.personal[campo]) {
            // if (!fields[campo]) errors[campo] = "El campo celular es equerido";
          }
          break;
        case "email":
          if (!this.state.datos.personal[campo]) {
            if (!fields[campo]) errors[campo] = "El campo email es requerido";
            else if (!this.validEmailRegex.test(fields["email"]))
              errors[campo] = "Email no es válido";
          }
          break;
        default:
          break;
      }

      return errors;
    });

    this.setState({ errors: errors });
    if (Object.keys(errors).length > 0) formIsValid = false;
   
    return formIsValid;
  }
  
  updatePersonal(e) {
    e.preventDefault();
    
    // saving in variables
    if (this.handleValidation()) {
      let datos = this.state.datos;
      let fields = this.state.fields;

      Object.keys(fields).map((clave) => {
        datos.personal[clave] = fields[clave];

        return datos;
      });
      this.setState({ datos: datos });

      // sent to the new page
      this.props.history.push({
        pathname: "/rescate",
        state: this.state.datos,
      });
    }
  }

  render() {
    return (
      <Container className="mt-5">
        <Row className="subtitle mb-3">
          <Col>Solicitar rescate Vida con Ahorro</Col>
        </Row>
        <Row>
          <Col className="ml-5 mt-3">
            <Row className="bold font-weight-bold mb-3 mt-3">
              <Col><h3>Datos del cliente</h3></Col>
            </Row>
            <Row>
              <Col><b>Nombre*</b></Col>
              <Col><b>Teléfono</b></Col>
            </Row>
            <Row>
              <Col>
                {this.state.datos.personal.firstName +
                  " " +
                  this.state.datos.personal.lastName}
              </Col>
              
              <Col>{this.state.datos.personal.telefono}
                <div className="ej">
                  {this.state.errors["telefono"] ? (
                    <span style={{ color: "#a94442" }}>
                      {this.state.errors["telefono"]}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
              {/**
              <Col className="">
                {this.state.datos.personal.telefono ? (
                  <Col className="">{this.state.datos.personal.telefono}</Col>
                ) : (
                  <React.Fragment>
                    <NumberFormat 
                      format="###########" 
                      onChange={this.handleChange.bind(this, "telefono")} 
                      name="telefono" 
                      value={this.state.fields["telefono"] || "562"} />
                    <div className="ej">
                      {this.state.errors["telefono"] ? (
                        <span style={{ color: "#a94442" }}>
                          {this.state.errors["telefono"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Col>
            */}
            </Row>
            <Row>
              <Col>&nbsp;</Col>
              <Col>&nbsp;</Col>
            </Row>
            <Row>
              <Col className=""><b>Celular</b></Col>
              <Col className=""><b>Email*</b></Col>
            </Row>
            <Row>
              <Col>{this.state.datos.personal.sms}
              {this.state.errors["sms"] &&
                <Alert className="msg-error-danger">
                  <p>{this.state.errors["sms"]}</p>
                </Alert>
              }
              </Col>
              <Col>{this.state.datos.personal.email}
                {this.state.errors["email"] &&
                  <Alert className="msg-error-danger">
                    <p>{this.state.errors["email"]}</p>
                  </Alert>
                }
              </Col>

              {/*
              <Col className="">
                {this.state.datos.personal.sms ? (
                  <Col className="">{this.state.datos.personal.sms}</Col>
                ) : (
                  <React.Fragment>
                    <NumberFormat 
                      prefix={569}
                      format="###########" 
                      onChange={this.handleChange.bind(this, "sms")} 
                      name="sms" 
                      value={this.state.fields["sms"] || "569"} />
                    <div className="ej">
                      {this.state.errors["sms"] ? (
                        <span style={{ color: "#a94442" }}>
                          {this.state.errors["sms"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Col>
              */}
              {/*
              <Col className="">
                {this.state.datos.personal.email ? (
                  <Col className="">{this.state.datos.personal.email}</Col>
                ) : (
                  <React.Fragment>
                    <input
                      className=""
                      type="text"
                      name="email"
                      onChange={this.handleChange.bind(this, "email")}
                      value={this.state.fields["email"] || ""}
                    />
                    <div className="ej">
                      {this.state.errors["email"] ? (
                        <span style={{ color: "#a94442" }}>
                          {this.state.errors["email"]}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </React.Fragment>
                )}
              </Col>
              */ }
            </Row>
            <Row className="mt-3 d-flex justify-content-center">
              <button
                className="info_btn"
                type="button"
                onClick={this.updatePersonal.bind(this)}>
                <span className="ui-button-text ui-c ">Continuar</span>
              </button>
            </Row>
            <Row className="d-flex justify-content-center">
              <p className="resend_code_label " onClick={this.props.logout}>
                Cerrar Sesión
              </p>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BodyPersonales);
