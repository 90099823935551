import React from "react";
import img_banner from "../../assets/css/img/banner.jpg";

const banner = (props) => {
  return (
    <div className="banner">
      <img className="img-responsive" src={img_banner} alt="banner" />
    </div>
  );
};

export default banner;
