import React from "react";

const footer = (props) => {
  return (
    <div id="footer">
      <div
        className="text-center pb-3"
        style={{ 
          fontFamily: 'Open Sans',
          fontWeight: 'bold',
          fontSize: 'calc(.7em + .5vw)',
          color: '#666666',
        }}
      >
        Si necesitas ayuda, contactanos al 600 444 8080
      </div>
      <div className="subcontent">
        Seguro Intermediado por BancoEstado Corredores de Seguros S.A. La
        Compañía que asume los riesgos es MetLife Chile Seguros de Vida S.A.
        condiciones depositadas en la CMF bajo el siguiente código POL 3 2020
        0138 y CAD 2 2017 0015. <br />
        BancoEstado Corredores de Seguros S.A. es filial de BancoEstado, el cual
        posee el 50,1% de la propiedad de dicha entidad. El restante 49,9%
        corresponde a MetLife Chile Inversiones Limitada. V{process.env.REACT_APP_VERSION}
      </div>
    </div>
  );
};

export default footer;
