import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Container, Alert } from "react-bootstrap";
import MetlifeService from "../../services/MetlifeDataService";
import Planes from "./Planes";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

import loading from "../../assets/css/img/loading-orange.gif";

class BodyInicio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datos: null,
      plan: null,
      uf: 0,
      errors: {},
      certificado: "",
      show: false,
    };
    this.handlePlan = this.handlePlan.bind(this);
    this.state.datos = this.props.datos;
  }
  //Funcion handlePlan 
  handlePlan = (value) => this.setState({ plan: value, errors: {}, show:false });

  async validatePlan() {
    let errors = {};
    this.setState({ errors: errors }); 
    let policies = this.state.planes;
    var bar = new Promise((resolve, reject) => {
      policies.forEach((item, index, array) => {
          if (item.ticketNumber === null) {
            errors["opcion"] = "En este momento no puedes iniciar un nuevo retiro de fondos ya que tienes uno anterior en curso.";
            this.setState({ errors: errors, show:true }); 
          }
          if (index === array.length -1) resolve();
      });
  });
  
  return bar.then(() => {
    let errors = this.state.errors;
    if (this.state.plan === null && typeof this.state.errors["opcion"] === 'undefined') {
      errors["opcion"] = "Seleccione un plan";
      this.setState({ errors: errors, show:true });
    } 
    if (typeof this.state.errors["opcion"] === 'undefined') { return true; }
    else { return false; }
  });
  }

  // Calling web servicesb
  async solicitarRescate() {
    let valid = await this.validatePlan();
    if (valid) {
      this.props.history.push({
        pathname: "/personales",
        state: {
          personal: this.state.datos,
          plan: this.state.plan,
          uf: this.state.uf,
        },
      });
      
    }
  }
  // did mount component
  componentDidMount() {
    this.setState({ isFetching: true });
    let uf = "0";
    
    MetlifeService.getUF()
      .then((res) => {
        uf = res.data.value;
        this.setState({ uf: res.data.value, });

        let planes = [];
        let policies = this.props.datos.policies;
        policies.map((plan) => {
          let temp = {};
          temp.nombre = "Seguro de Muerte Accidental con Ahorro";//plan.descriptionProduct;
          temp.fecha_inicio = plan.startDate;
          temp.primas_pagadas = plan.numberApaymentPay;
          temp.prima_mensual_clp = uf * plan.apaymentUf;
          temp.prima_mensual_uf = plan.apaymentUf;
          temp.monto_pagado_clp = uf * plan.amountPayApaymentIfUf;
          temp.monto_pagado_uf = plan.amountPayApaymentIfUf;
          temp.monto_disponible_clp = uf * plan.availableBalance;
          temp.monto_disponible_uf = plan.availableBalance;
          temp.ahorro_mensual_clp = 0;
          temp.ahorro_mensual_uf = 0;
          temp.branch = plan.branch;
          temp.policy = plan.policy;
          temp.product = plan.product;
          temp.ticketNumber = plan.ticketNumber;
          temp.rescates = [];
          temp.errorMessage = plan.errorMessage;
          temp.errorCode = plan.errorCode;

          planes.push(temp);

          return temp;
        });
        this.setState({
          planes: planes,
          certificado: "https://www.dropbox.com/s/essj2rir842rbkn/springer.pdf.pdf",
          isFetching: false,
        });
      })
      .catch((e) => {
        uf = "0";
        let planes = [];    
        let policies = this.props.datos.policies;
        if (policies === null) { policies = []; }

        policies.map((plan) => {
          let temp = {};
          temp.nombre = "Seguro de Muerte Accidental con Ahorro";//plan.descriptionProduct;
          temp.fecha_inicio = plan.startDate;
          temp.primas_pagadas = plan.numberApaymentPay;
          temp.prima_mensual_clp = uf * plan.apaymentUf;
          temp.prima_mensual_uf = plan.apaymentUf;
          temp.monto_pagado_clp = uf * plan.amountPayApaymentIfUf;
          temp.monto_pagado_uf = plan.amountPayApaymentIfUf;
          temp.monto_disponible_clp = uf * plan.availableBalance;
          temp.monto_disponible_uf = plan.availableBalance;
          temp.ahorro_mensual_clp = 0;
          temp.ahorro_mensual_uf = 0;
          temp.branch = plan.branch;
          temp.policy = plan.policy;
          temp.product = plan.product;
          temp.ticketNumber = plan.ticketNumber;
          temp.rescates = [];
          temp.errorMessage = plan.errorMessage;
          temp.errorCode = plan.errorCode;

          planes.push(temp);

          return temp;
        });
        this.setState({
          planes: planes,
          certificado: "https://www.dropbox.com/s/essj2rir842rbkn/springer.pdf.pdf",
          isFetching: false,
        });
      });
  }

  render() {
    return (
      <Container className="mt-5">
        <React.Fragment>
          <Row className="subtitle mb-2">
            <Col>Consulta</Col>
          </Row>
          <Row className="ini_texto">
            <Col>
            <span>
              Esta es la información del saldo acumulado de cada unos de tus
              seguros con ahorro, si deseas retirar un monto total o
              parcial de alguno de ellos, debes seleccionar el seguro del cual
              deseas realizar el retiro y luego hacer click en el botón&nbsp;
              <b className="font-weight-bold">solicitar ahorros</b>
            </span>
            </Col>
          </Row>
          <Row className="mt-3 mb-2">
            <Col>
              {this.state.planes == null ?
                (<div className="btn-continuar"><img className="center" src={loading} alt="loading" /></div>) :
                (<Planes planes={this.state.planes} uf={this.state.uf} handlePlan={this.handlePlan} rut={this.state.datos.rut} token={this.state.datos.token} nombre={this.state.datos.nombre}/>)
              }
            </Col>
          </Row>
          <Row>
            {this.state.show && 
              <Col xs={12} md={12} className="mt-2">
                <Alert className="msg-error" variant="primary" onClose={() => this.setState({show:false})} dismissible>
                    <b>{this.state.errors["opcion"]}</b>
                </Alert>
              </Col>
            }
          </Row>
          <Row className="ini_notification">
            <Col className="img-responsive" md={1} xs={1}>
              {/*<p>!</p>*/}
            </Col>
            <Col xs={10} md={11}>
              <ul>
                <li>
                El 21 de abril de 2022, el Servicio de Impuestos Internos (SII) definió que, 
                tanto los rescates parciales como los rescates totales que realicen los clientes, 
                podrían quedar afectos al pago del impuesto a la renta y las Compañías de Seguros deberán retener dicho impuesto. 
                Por lo tanto, el monto final a recibir del rescate, podría ser menor al solicitado.
                </li>
                <li>
                  El asegurado puede realizar un rescate por mes, con un máximo
                  de 4 retiros al año parciales o total de la cuenta de
                  capacitación y ahorro.
                </li>
                <li>
                  <span className="font-weight-bold">Importante:</span>
                  &nbsp; se debe tener en consideración que puede existir un
                  cobro de prima en curso.
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="mt-3 d-flex justify-content-center">
            <button
              className="info_btn"
              type="button"
              onClick={this.solicitarRescate.bind(this)}
              // disabled={this.state.plan === null ? "disabled" : ""}
            >
              <span className="ui-button-text ui-c ">Solicitar ahorros</span>
            </button>
          </Row>
          <Row className="d-flex justify-content-center"><p className="resend_code_label " onClick={this.props.logout}>Cerrar Sesión</p></Row>
        </React.Fragment>
      </Container>
    );
  }
}

export default withRouter(BodyInicio);