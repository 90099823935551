import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import "bootstrap/dist/css/bootstrap.css";

export default function PopupRut(props) {
  const [show, setShow] = useState(true);

  //const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={show}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            La plataforma no está disponible para el RUT ingresado.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='text-justify px-4'>
            {/* <Row className="justify-content-md-center">{props.mensaje}</Row> */}

            <Row className="justify-content-md-center">
              - Si gestionaste un retiro recientemente, reintenta mañana cuando
              tus montos estén actualizados.
            </Row>
            <Row className="justify-content-md-center">
              - Si contrataste recientemente, es posible que aún se esté
              gestionando tu acceso.
            </Row>
            <Row className="justify-content-md-center">
              - Si los casos anteriores no aplican, revisa haber escrito el RUT
              correcto.
            </Row>

            {/* <Row></Row> */}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row 
              className="justify-content-md-center text-center mb-3"
              style={{
                fontWeight: 'bold', 
                fontSize: '18px',
                color: 'rgb(102, 102, 102)'
              }}
            >
              Si necesitas ayuda, contáctanos al 6004448080
            </Row>
            <Row className="justify-content-md-center">
              <button className="info_btn" type="button" onClick={handleClose}>
                <span className="ui-button-text ui-c ">Salir</span>
              </button>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
