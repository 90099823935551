import React from "react";
import { Container, Row, Table } from "react-bootstrap";
import NumberFormat from "react-number-format";
import "../../assets/css/Landing/table_res.css";

export default function SimularTable(props) {

  let simulations = props.simulations;
  let plan = props.plan;
  let uf = props.uf;
  return (
    plan.ticketNumber === null ? (<div><span style={{ color: "#a94442" }}>Esta función no está disponible porque tienes un retiro de ahorros en curso y debemos volver a calcular tu monto disponible.</span></div>) : (
      <Container>
        <Row>
          <Table responsive className="planes_table mt-1 sim_table">
            <thead>
              <tr>
                <th>Plazo</th>
                <th>Hoy</th>
                <th>1 Mes</th>
                <th>2 Meses</th>
                <th>3 Meses</th>
                <th>6 Meses</th>
                <th>1 Año</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>% a retirar</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
                <td>100%</td>
              </tr>
              <tr>
                <td>Disponible para retiro (UF)</td>
                <td>
                  <NumberFormat
                    value={plan.monto_disponible_uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[1]}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[2]}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[3]}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[6]}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[12]}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"UF "}
                    suffix={""}
                    decimalScale={4}
                  />
                </td>
              </tr>
              <tr>
                <td>Disponible para retiro ($)</td>
                <td>
                  <NumberFormat
                    value={plan.monto_disponible_clp}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[1] * uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[2] * uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[3] * uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[6] * uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
                <td>
                  <NumberFormat
                    value={simulations[12] * uf}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    prefix={"$ "}
                    suffix={"*"}
                    decimalScale={0}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row className="minilabel">
          <NumberFormat
            value={props.uf}
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            prefix={"*Monto referencial calculado con el valor UF del día de hoy $ "}
            decimalScale={2}
          />
        </Row>
        <Row className="minilabel">
          <p>* el cálculo considera el pago regular de la prima</p>
        </Row>
      </Container>
    )
  );
}
